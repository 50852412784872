import roundDateToWeekday from '@/utilities/roundDateToWeekday';
import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';

import { TaskData, TaskStatus } from '@/classes/Task';

import { AbstractDialog } from './AbstractDialog';

import {
  TASK_NAME_ID,
  TASK_NAME_LABEL,
  TASK_LINK_ID,
  TASK_LINK_LABEL,
  TASK_START_ID,
  TASK_START_LABEL,
  TASK_END_ID,
  TASK_END_LABEL,
  TASK_STATUS_ID,
  TASK_STATUS_LABEL,
  TASK_ID_ID,
  TASK_STATUS_OPTIONS,
  TASK_EDIT_BUTTON_LABEL,
  TASK_DELETE_BUTTON_ID,
  TASK_DELETE_BUTTON_LABEL,
} from '@/constants';

interface EditTaskData extends TaskData {
  id: string;
  min?: Date;
  max?: Date;
  delete?: boolean;
}

export class EditTaskDialog extends AbstractDialog {
  constructor(container: HTMLElement, callback: (data: EditTaskData) => void) {
    super(container);

    const formElements: FormElement[] = [
      {
        type: 'input',
        attributes: {
          type: 'hidden',
          id: TASK_ID_ID,
          name: TASK_ID_ID,
        },
      },
      {
        type: 'input',
        attributes: {
          id: TASK_NAME_ID,
          label: TASK_NAME_LABEL,
          type: 'text',
          autocomplete: 'off',
        },
      },
      {
        type: 'input',
        attributes: {
          id: TASK_LINK_ID,
          label: TASK_LINK_LABEL,
          type: 'url',
          autocomplete: 'off',
        },
      },
      {
        type: 'input',
        attributes: {
          id: TASK_START_ID,
          label: TASK_START_LABEL,
          type: 'date',
          required: 'true',
        },
      },
      {
        type: 'input',
        attributes: {
          id: TASK_END_ID,
          label: TASK_END_LABEL,
          type: 'date',
          required: 'true',
        },
      },
      {
        type: 'select',
        attributes: {
          id: TASK_STATUS_ID,
          label: TASK_STATUS_LABEL,
          required: 'true',
        },
        options: TASK_STATUS_OPTIONS,
      },
      {
        type: 'button',
        attributes: {
          type: 'submit',
        },
        content: TASK_EDIT_BUTTON_LABEL,
      },
      {
        type: 'hr',
      },
      {
        type: 'button',
        attributes: {
          type: 'button',
          id: TASK_DELETE_BUTTON_ID,
        },
        content: TASK_DELETE_BUTTON_LABEL,
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const taskIdInput = this.dialog.querySelector(
      `#${TASK_ID_ID}`,
    ) as HTMLInputElement;

    const taskNameInput = this.dialog.querySelector(
      `#${TASK_NAME_ID}`,
    ) as HTMLInputElement;

    const taskLinkInput = this.dialog.querySelector(
      `#${TASK_LINK_ID}`,
    ) as HTMLInputElement;

    const startInput = this.dialog.querySelector(
      `#${TASK_START_ID}`,
    ) as HTMLInputElement;

    startInput.addEventListener('change', () => {
      endInput.min = startInput.value;
    });

    const endInput = this.dialog.querySelector(
      `#${TASK_END_ID}`,
    ) as HTMLInputElement;

    endInput.addEventListener('change', () => {
      startInput.max = endInput.value;
    });

    const statusInput = this.dialog.querySelector(
      `#${TASK_STATUS_ID}`,
    ) as HTMLSelectElement;

    this.dialog.addEventListener('submit', (event) => {
      event.preventDefault();

      callback({
        id: taskIdInput.value,
        name: taskNameInput.value,
        link: taskLinkInput.value,
        start: roundDateToWeekday(new Date(startInput.value), 'nextMonday'),
        end: roundDateToWeekday(new Date(endInput.value), 'previousFriday'),
        status: statusInput.value as TaskStatus,
      });

      this.closeDialog();
    });

    const deleteButton = this.dialog.querySelector(
      `#${TASK_DELETE_BUTTON_ID}`,
    ) as HTMLButtonElement;

    deleteButton.addEventListener('click', () => {
      callback({
        id: taskIdInput.value,
        name: taskNameInput.value,
        link: taskLinkInput.value,
        start: roundDateToWeekday(new Date(startInput.value), 'nextMonday'),
        end: roundDateToWeekday(new Date(endInput.value), 'previousFriday'),
        status: statusInput.value as TaskStatus,
        delete: true,
      });

      this.closeDialog();
    });
  }

  setCurrentTask(task: EditTaskData): void {
    const taskNameInput = this.dialog.querySelector(
      `#${TASK_NAME_ID}`,
    ) as HTMLInputElement;
    taskNameInput.value = task.name;

    const taskLinkInput = this.dialog.querySelector(
      `#${TASK_LINK_ID}`,
    ) as HTMLInputElement;
    taskLinkInput.value = task.link;

    const startInput = this.dialog.querySelector(
      `#${TASK_START_ID}`,
    ) as HTMLInputElement;
    startInput.value = task.start.toISOString().split('T')[0];
    startInput.max = task.end.toISOString().split('T')[0];

    if (task.min) {
      startInput.min = task.min.toISOString().split('T')[0];
    }

    const endInput = this.dialog.querySelector(
      `#${TASK_END_ID}`,
    ) as HTMLInputElement;
    endInput.value = task.end.toISOString().split('T')[0];
    endInput.min = task.start.toISOString().split('T')[0];

    if (task.max) {
      endInput.max = task.max.toISOString().split('T')[0];
    }

    const statusInput = this.dialog.querySelector(
      `#${TASK_STATUS_ID}`,
    ) as HTMLSelectElement;
    statusInput.value = task.status;

    const taskIdInput = this.dialog.querySelector(
      `#${TASK_ID_ID}`,
    ) as HTMLInputElement;
    taskIdInput.value = task.id;
  }

  openDialogWithData(task: EditTaskData): void {
    this.setCurrentTask(task);
    this.openDialog();
  }
}
