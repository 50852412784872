import roundDateToWeekday from '@/utilities/roundDateToWeekday';
import { AbstractDialog } from './AbstractDialog';
import { PTOData } from './AddPTODialog';
import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';
import {
  DELETE_PTO_BUTTON_ID,
  DELETE_PTO_BUTTON_TEXT,
  DEVELOPER_ID_ID,
  EDIT_PTO_BUTTON_TEXT,
  PTO_END_ID,
  PTO_END_LABEL,
  PTO_INDEX_ID,
  PTO_START_ID,
  PTO_START_LABEL,
  PTO_TYPE_ID,
  PTO_TYPE_LABEL,
  PTO_TYPE_OPTIONS,
} from '@/constants';
import { PTOType } from '@/classes/Developer';
import { log } from 'console';

export interface EditPTOData extends PTOData {
  ptoIndex: number;
  min?: Date;
  max?: Date;
  delete?: boolean;
}

export class EditPTODialog extends AbstractDialog {
  constructor(container: HTMLElement, callback: (data: EditPTOData) => void) {
    super(container);

    const elements: FormElement[] = [
      {
        type: 'input',
        attributes: {
          type: 'hidden',
          id: DEVELOPER_ID_ID,
          name: DEVELOPER_ID_ID,
        },
      },
      {
        type: 'input',
        attributes: {
          type: 'hidden',
          id: PTO_INDEX_ID,
          name: PTO_INDEX_ID,
        },
      },
      {
        type: 'select',
        attributes: {
          id: PTO_TYPE_ID,
          label: PTO_TYPE_LABEL,
          required: 'true',
        },
        options: PTO_TYPE_OPTIONS,
      },
      {
        type: 'input',
        attributes: {
          id: PTO_START_ID,
          name: PTO_START_ID,
          type: 'date',
          label: PTO_START_LABEL,
          required: 'true',
        },
      },
      {
        type: 'input',
        attributes: {
          id: PTO_END_ID,
          name: PTO_END_ID,
          type: 'date',
          label: PTO_END_LABEL,
          required: 'true',
        },
      },
      {
        type: 'button',
        attributes: {
          type: 'submit',
        },
        content: EDIT_PTO_BUTTON_TEXT,
      },
      {
        type: 'hr',
      },
      {
        type: 'button',
        attributes: {
          type: 'button',
          id: DELETE_PTO_BUTTON_ID,
        },
        content: DELETE_PTO_BUTTON_TEXT,
      },
    ];

    this.dialog.innerHTML = generateFormHtml(elements);

    const startInput = this.dialog.querySelector(
      `#${PTO_START_ID}`,
    ) as HTMLInputElement;
    const endInput = this.dialog.querySelector(
      `#${PTO_END_ID}`,
    ) as HTMLInputElement;

    const ptoIndexInput = this.dialog.querySelector(
      `#${PTO_INDEX_ID}`,
    ) as HTMLInputElement;

    const developerIdInput = this.dialog.querySelector(
      `#${DEVELOPER_ID_ID}`,
    ) as HTMLInputElement;

    const ptoType = this.dialog.querySelector(
      `#${PTO_TYPE_ID}`,
    ) as HTMLSelectElement;

    startInput.addEventListener('change', () => {
      endInput.min = startInput.value;
    });

    endInput.addEventListener('change', () => {
      startInput.max = endInput.value;
    });

    this.dialog.addEventListener('submit', (event) => {
      event.preventDefault();

      callback({
        developerId: developerIdInput.value,
        ptoIndex: parseInt(ptoIndexInput.value),
        type: ptoType.value as PTOType,
        start: roundDateToWeekday(new Date(startInput.value), 'nextMonday'),
        end: roundDateToWeekday(new Date(endInput.value), 'previousFriday'),
      });

      this.closeDialog();
    });

    const deleteButton = this.dialog.querySelector(
      `#${DELETE_PTO_BUTTON_ID}`,
    ) as HTMLButtonElement;

    deleteButton.addEventListener('click', () => {
      callback({
        developerId: developerIdInput.value,
        ptoIndex: parseInt(ptoIndexInput.value),
        type: ptoType.value as PTOType,
        start: roundDateToWeekday(new Date(startInput.value), 'nextMonday'),
        end: roundDateToWeekday(new Date(endInput.value), 'previousFriday'),
        delete: true,
      });

      this.closeDialog();
    });
  }

  setCurrentPTO(data: EditPTOData): void {
    const startInput = this.dialog.querySelector(
      `#${PTO_START_ID}`,
    ) as HTMLInputElement;
    startInput.value = data.start.toISOString().split('T')[0];

    if (data.min) {
      startInput.min = data.min.toISOString().split('T')[0];
    }
    startInput.max = data.end.toISOString().split('T')[0];

    const endInput = this.dialog.querySelector(
      `#${PTO_END_ID}`,
    ) as HTMLInputElement;
    endInput.value = data.end.toISOString().split('T')[0];

    if (data.max) {
      endInput.max = data.max.toISOString().split('T')[0];
    }
    endInput.min = data.start.toISOString().split('T')[0];

    const ptoIndexInput = this.dialog.querySelector(
      `#${PTO_INDEX_ID}`,
    ) as HTMLInputElement;
    ptoIndexInput.value = data.ptoIndex.toString();

    const developerIdInput = this.dialog.querySelector(
      `#${DEVELOPER_ID_ID}`,
    ) as HTMLInputElement;
    developerIdInput.value = data.developerId;

    const ptoType = this.dialog.querySelector(
      `#${PTO_TYPE_ID}`,
    ) as HTMLSelectElement;
    ptoType.value = data.type || PTOType.PTO;
  }

  openDialogWithData(data: EditPTOData): void {
    this.setCurrentPTO(data);
    this.openDialog();
  }
}
