import { AbstractDialog } from './AbstractDialog';

export class ImportChartDialog extends AbstractDialog {
  constructor(container: HTMLElement, callback: (data: string) => void) {
    super(container);

    this.dialog.innerHTML = `
            <form>
                <textarea type="text" id="import-field" rows="20"></textarea>
                <button type="button" id="import-button">Import</button>
            </form>
        `;

    const importButton = this.dialog.querySelector(
      '#import-button',
    ) as HTMLButtonElement;

    importButton.addEventListener('click', () => {
      const importField = this.dialog.querySelector(
        '#import-field',
      ) as HTMLInputElement;
      callback(importField.value);

      importField.value = '';
      this.closeDialog();
    });
  }
}
