import { Chart } from '@/classes/Chart';
import toBinaryStr from './toBinaryStr';

export default function convertChartToString(chart: Chart): string {
  const developers = chart.getDevelopers();

  return btoa(
    toBinaryStr(
      JSON.stringify({
        timestamp: Date.now(),
        name: chart.getName(),
        start: chart.getStart(),
        end: chart.getEnd(),
        developers: developers.map((developer) => ({
          name: developer.getName(),
          tasks: developer.getTasks().map((task) => ({
            name: task.getName(),
            link: task.getLink(),
            start: task.getStart(),
            end: task.getEnd(),
            status: task.getStatus(),
          })),
          ptos: developer.getPTOs(),
        })),
      }),
    ),
  );
}
