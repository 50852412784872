import { Chart } from '@/classes/Chart';

import saveChartToStorage from '@/utilities/saveChartToStorage';
import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';

import {
  CHART_NAME_ID,
  CHART_START_ID,
  CHART_END_ID,
  CREATE_CHART_FORM_ID,
  CREATE_CHART_FORM_TITLE,
  CREATE_CHART_BUTTON_TEXT,
  CHART_END_LABEL,
  CHART_NAME_LABEL,
  CHART_START_LABEL,
} from '@/constants';

export default function createEmptyChart() {
  const container = document.getElementById('app');
  if (!container) throw new Error('App container not found');

  const formElements: FormElement[] = [
    { type: 'h1', content: CREATE_CHART_FORM_TITLE },
    {
      type: 'input',
      attributes: {
        type: 'text',
        id: CHART_NAME_ID,
        name: CHART_NAME_ID,
        required: 'true',
        label: CHART_NAME_LABEL,
      },
    },
    {
      type: 'input',
      attributes: {
        type: 'date',
        id: CHART_START_ID,
        name: CHART_START_ID,
        required: 'true',
        label: CHART_START_LABEL,
      },
    },
    {
      type: 'input',
      attributes: {
        type: 'date',
        id: CHART_END_ID,
        name: CHART_END_ID,
        required: 'true',
        label: CHART_END_LABEL,
      },
    },
    {
      type: 'button',
      attributes: { type: 'submit' },
      content: CREATE_CHART_BUTTON_TEXT,
    },
  ];

  const formHtml = generateFormHtml(formElements, true, {
    id: CREATE_CHART_FORM_ID,
    class: CREATE_CHART_FORM_ID,
  });

  container.appendChild(formHtml);

  formHtml.addEventListener('submit', (event) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const chartNameInput = form.querySelector(
      `#${CHART_NAME_ID}`,
    ) as HTMLInputElement;
    const chartStartInput = form.querySelector(
      `#${CHART_START_ID}`,
    ) as HTMLInputElement;
    const chartEndInput = form.querySelector(
      `#${CHART_END_ID}`,
    ) as HTMLInputElement;

    saveChartToStorage(
      new Chart({
        container,
        name: chartNameInput.value,
        start: new Date(chartStartInput.value),
        end: new Date(chartEndInput.value),
        developers: [],
      }),
    );

    form.remove();
    location.reload();
  });
}
