import { Developer } from '@/classes/Developer';

import { DEVELOPER_SELECT_ID } from '@/constants';

export class AbstractDialog {
  protected container: HTMLElement;
  protected dialog: HTMLDialogElement;

  constructor(container: HTMLElement) {
    this.container = container;
    this.dialog = document.createElement('dialog');

    this.dialog.addEventListener('click', (event) => {
      const rect = this.dialog.getBoundingClientRect();
      if (
        event.clientX < rect.left - 10 ||
        event.clientX > rect.right + 10 ||
        event.clientY < rect.top - 10 ||
        event.clientY > rect.bottom + 10
      ) {
        this.closeDialog();
      }
    });
  }

  destroy(): void {
    this.dialog.remove();
    this.container.removeChild(this.dialog);
  }

  render(): void {
    this.container.appendChild(this.dialog);
  }

  openDialog(): void {
    this.dialog.showModal();
  }

  closeDialog(): void {
    this.dialog.close();
  }

  // When we add developers, we need to update the select element with the new developers
  updateDevelopers(developers: Developer[]): void {
    const developerSelect = this.dialog.querySelector(
      `#${DEVELOPER_SELECT_ID}`,
    ) as HTMLSelectElement;
    developerSelect.innerHTML = `
            <option value="" disabled selected>Select a Developer</option>
            ${developers.map((developer) => `<option value="${developer.getId()}">${developer.getName()}</option>`).join('')}
        `;
  }
}
