export type roundDirection = 'nextMonday' | 'previousFriday';

export default function roundDateToWeekday(
  date: Date,
  direction: roundDirection,
): Date {
  if (date.getDay() > 0 && date.getDay() < 6) {
    return date;
  }

  const newDate = new Date(date);

  if (direction === 'nextMonday') {
    while (newDate.getDay() !== 1) {
      newDate.setDate(newDate.getDate() + 1);
    }
  } else {
    while (newDate.getDay() !== 5) {
      newDate.setDate(newDate.getDate() - 1);
    }
  }

  return newDate;
}
