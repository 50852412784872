import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';

import { AbstractDialog } from './AbstractDialog';

import {
  DEVELOPER_NAME_ID,
  DEVELOPER_NAME_LABEL,
  ADD_DEVELOPER_BUTTON_TEXT,
} from '@/constants';

export class AddDeveloperDialog extends AbstractDialog {
  constructor(
    container: HTMLElement,
    callback: (developerName: string) => void,
  ) {
    super(container);

    const formElements: FormElement[] = [
      {
        type: 'input',
        attributes: {
          type: 'text',
          id: DEVELOPER_NAME_ID,
          name: DEVELOPER_NAME_ID,
          autocomplete: 'off',
          autofocus: 'true',
          required: 'true',
          label: DEVELOPER_NAME_LABEL,
        },
      },
      {
        type: 'button',
        attributes: {
          type: 'submit',
        },
        content: ADD_DEVELOPER_BUTTON_TEXT,
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const developerNameInput = this.dialog.querySelector(
      `#${DEVELOPER_NAME_ID}`,
    ) as HTMLInputElement;

    this.dialog.addEventListener('submit', (event) => {
      event.preventDefault();

      callback(developerNameInput.value);

      developerNameInput.value = '';

      this.closeDialog();
    });
  }
}
