export interface FormElement {
  type: 'h1' | 'input' | 'button' | 'select' | 'hr';
  attributes?: { [key: string]: string };
  content?: string;
  options?: { value: string; text: string }[];
}

export default function generateFormHtml(
  elements: FormElement[],
  returnAsElement: true,
  formAttributes?: { [key: string]: string },
): HTMLElement;
export default function generateFormHtml(
  elements: FormElement[],
  returnAsElement?: false,
  formAttributes?: { [key: string]: string },
): string;

export default function generateFormHtml(
  elements: FormElement[],
  returnAsElement: boolean = false,
  formAttributes?: { [key: string]: string },
): string | HTMLElement {
  const htmlElements = elements
    .map((element) => {
      const attrs = element.attributes
        ? Object.entries(element.attributes)
            .map(([key, value]) => `${key}="${value}"`)
            .join(' ')
        : '';
      const content = element.content || '';

      if (element.type === 'input' && element.attributes?.label) {
        const label = `<label for="${element.attributes.id}">${element.attributes.label}</label>`;
        return `${label}\n<input ${attrs}>`;
      }

      if (element.type === 'select' && element.attributes?.label) {
        const label = `<label for="${element.attributes.id}">${element.attributes.label}</label>`;
        const options = element.options
          ? element.options
              .map(
                (option) =>
                  `<option value="${option.value}">${option.text}</option>`,
              )
              .join('\n')
          : '';
        return `${label}\n<select ${attrs}>${options}</select>`;
      }

      return `<${element.type} ${attrs}>${content}</${element.type}>`;
    })
    .join('\n');

  const formAttrs = formAttributes
    ? Object.entries(formAttributes)
        .map(([key, value]) => `${key}="${value}"`)
        .join(' ')
    : '';

  const formHtml = `<form ${formAttrs}>${htmlElements}</form>`;

  if (returnAsElement) {
    const template = document.createElement('template');
    template.innerHTML = formHtml.trim();
    return template.content.firstChild as HTMLElement;
  } else {
    return formHtml;
  }
}
