import { AbstractDialog } from './AbstractDialog';
import { Developer, PTO, PTOType } from '@/classes/Developer';
import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';
import roundDateToWeekday from '@/utilities/roundDateToWeekday';

import {
  DEVELOPER_SELECT_ID,
  PTO_START_ID,
  PTO_END_ID,
  PTO_START_LABEL,
  PTO_END_LABEL,
  ADD_PTO_BUTTON_TEXT,
  DEVELOPER_LABEL,
  PTO_TYPE_ID,
  PTO_TYPE_LABEL,
  PTO_TYPE_OPTIONS,
} from '@/constants';

export interface PTOData {
  developerId: string;
  type: PTOType;
  start: Date;
  end: Date;
}

export class AddPTODialog extends AbstractDialog {
  constructor(
    container: HTMLElement,
    developers: Developer[],
    min: Date,
    max: Date,
    callback: (data: PTOData) => void,
  ) {
    super(container);

    const formElements: FormElement[] = [
      {
        type: 'select',
        attributes: { id: DEVELOPER_SELECT_ID, label: DEVELOPER_LABEL },
        options: developers.map((dev) => ({
          value: dev.getId(),
          text: dev.getName(),
        })),
      },
      {
        type: 'select',
        attributes: { id: PTO_TYPE_ID, label: PTO_TYPE_LABEL },
        options: PTO_TYPE_OPTIONS,
      },
      {
        type: 'input',
        attributes: {
          type: 'date',
          id: PTO_START_ID,
          name: PTO_START_ID,
          required: 'true',
          label: PTO_START_LABEL,
          min: min.toISOString().split('T')[0],
          max: max.toISOString().split('T')[0],
        },
      },
      {
        type: 'input',
        attributes: {
          type: 'date',
          id: PTO_END_ID,
          name: PTO_END_ID,
          required: 'true',
          label: PTO_END_LABEL,
          min: min.toISOString().split('T')[0],
          max: max.toISOString().split('T')[0],
        },
      },
      {
        type: 'button',
        attributes: { type: 'submit' },
        content: ADD_PTO_BUTTON_TEXT,
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const startInput = this.dialog.querySelector(
      `#${PTO_START_ID}`,
    ) as HTMLInputElement;
    const endInput = this.dialog.querySelector(
      `#${PTO_END_ID}`,
    ) as HTMLInputElement;
    const developerId = this.dialog.querySelector(
      `#${DEVELOPER_SELECT_ID}`,
    ) as HTMLSelectElement;
    const ptoType = this.dialog.querySelector(
      `#${PTO_TYPE_ID}`,
    ) as HTMLSelectElement;

    startInput.addEventListener('change', () => {
      endInput.min = startInput.value;
    });

    endInput.addEventListener('change', () => {
      startInput.max = endInput.value;
    });

    this.dialog.addEventListener('submit', (event) => {
      event.preventDefault();

      callback({
        developerId: developerId.value,
        type: ptoType.value as PTOType,
        start: roundDateToWeekday(new Date(startInput.value), 'nextMonday'),
        end: roundDateToWeekday(new Date(endInput.value), 'previousFriday'),
      });

      developerId.value = '';
      startInput.value = '';
      endInput.value = '';

      this.closeDialog();
    });
  }
}
