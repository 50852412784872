import resetChart from '@/utilities/resetChart';
import { AbstractDialog } from './AbstractDialog';
import generateFormHtml, { FormElement } from '@/utilities/generateFormHtml';

import {
  CHART_NAME_ID,
  CHART_NAME_LABEL,
  CHART_START_ID,
  CHART_START_LABEL,
  CHART_END_ID,
  CHART_END_LABEL,
  CHART_DELETE_BUTTON_ID,
  CHART_DELETE_BUTTON_LABEL,
  CHART_EDIT_BUTTON_LABEL,
} from '@/constants';

export interface EditChartData {
  name: string;
  start: Date;
  end: Date;
}

export class EditChartDialog extends AbstractDialog {
  constructor(
    container: HTMLElement,
    name: string,
    start: Date,
    end: Date,
    callback: (data: EditChartData) => void,
  ) {
    super(container);

    const formElements: FormElement[] = [
      {
        type: 'input',
        attributes: {
          type: 'text',
          id: CHART_NAME_ID,
          name: CHART_NAME_ID,
          autocomplete: 'off',
          autofocus: 'true',
          required: 'true',
          label: CHART_NAME_LABEL,
        },
      },
      {
        type: 'input',
        attributes: {
          type: 'date',
          id: CHART_START_ID,
          name: CHART_START_ID,
          required: 'true',
          label: CHART_START_LABEL,
          value: start.toISOString().split('T')[0],
        },
      },
      {
        type: 'input',
        attributes: {
          type: 'date',
          id: CHART_END_ID,
          name: CHART_END_ID,
          required: 'true',
          label: CHART_END_LABEL,
          value: end.toISOString().split('T')[0],
        },
      },
      {
        type: 'button',
        attributes: {
          type: 'submit',
        },
        content: CHART_EDIT_BUTTON_LABEL,
      },
      {
        type: 'hr',
      },
      {
        type: 'button',
        attributes: {
          type: 'button',
          id: CHART_DELETE_BUTTON_ID,
        },
        content: CHART_DELETE_BUTTON_LABEL,
      },
    ];

    this.dialog.innerHTML = generateFormHtml(formElements);

    const chartNameInput = this.dialog.querySelector(
      `#${CHART_NAME_ID}`,
    ) as HTMLInputElement;
    chartNameInput.value = name;

    const startInput = this.dialog.querySelector(
      `#${CHART_START_ID}`,
    ) as HTMLInputElement;
    const endInput = this.dialog.querySelector(
      `#${CHART_END_ID}`,
    ) as HTMLInputElement;

    startInput.addEventListener('change', () => {
      endInput.min = startInput.value;
    });
    endInput.addEventListener('change', () => {
      startInput.max = endInput.value;
    });

    const deleteButton = this.dialog.querySelector(
      `#${CHART_DELETE_BUTTON_ID}`,
    ) as HTMLButtonElement;

    this.dialog.addEventListener('submit', (event) => {
      event.preventDefault();

      callback({
        name: chartNameInput.value,
        start: new Date(startInput.value),
        end: new Date(endInput.value),
      });

      this.closeDialog();
    });

    deleteButton.addEventListener('click', () => {
      if (confirm('Are you sure you want to reset the chart?')) {
        resetChart();
      }
    });
  }
}
