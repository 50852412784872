import { AddTaskDialog } from '@/dialogs/AddTaskDialog';
import { AddDeveloperDialog } from '@/dialogs/AddDeveloperDialog';
import { AddPTODialog } from '@/dialogs/AddPTODialog';
import { EditChartDialog } from '@/dialogs/EditChartDialog';
import { ExportChartDialog } from '@/dialogs/ExportChartDialog';
import { ImportChartDialog } from '@/dialogs/ImportChartDialog';
import { EditTaskDialog } from '@/dialogs/EditTaskDialog';
import { EditPTODialog } from '@/dialogs/EditPTODialog';
import { EditDeveloperDialog } from '@/dialogs/EditDeveloperDialog';

import saveChartToStorage from '@/utilities/saveChartToStorage';

import { Developer, PTOType } from './Developer';
import { Task, TaskStatus } from './Task';
import {
  ADD_DEVELOPER_BUTTON_TEXT,
  ADD_PTO_BUTTON_TEXT,
  ADD_TASK_BUTTON_LABEL,
  CHART_EDIT_BUTTON_LABEL,
  CHART_EXPORT_BUTTON_LABEL,
  CHART_IMPORT_BUTTON_LABEL,
  CHART_SAVE_BUTTON_LABEL,
  PTO_TYPE_PTO_LABEL,
  PTO_TYPE_SICK_LABEL,
} from '@/constants';
import convertStringToChart from '@/utilities/convertStringToChart';

export interface ChartData {
  container: HTMLElement;
  timestamp: number;
  name: string;
  start: Date;
  end: Date;
  developers: Developer[];
}

export class Chart {
  private container: HTMLElement;
  private name: string;
  private start: Date;
  private end: Date;
  private developers: Developer[];
  private timestamp: number;

  private addDeveloperDialog: AddDeveloperDialog;
  private addTaskDialog: AddTaskDialog;
  private addPTODialog: AddPTODialog;
  private editChartDialog: EditChartDialog;
  private exportChartDialog: ExportChartDialog;
  private importChartDialog: ImportChartDialog;
  private editTaskDialog: EditTaskDialog;
  private editPTODialog: EditPTODialog;
  private editDeveloperDialog: EditDeveloperDialog;

  constructor(data: ChartData) {
    this.container = data.container;
    this.name = data.name;
    this.start = data.start;
    this.end = data.end;
    this.developers = data.developers || [];
    this.timestamp = data.timestamp || 0;

    this.addTaskDialog = new AddTaskDialog(
      this.container,
      this.developers,
      this.start,
      this.end,
      (taskData) => {
        const developer = this.developers.find(
          (developer) => developer.getId() === taskData.developerId,
        );

        if (developer) {
          const { developerId, ...taskDataWithoutId } = taskData;
          developer.addTask(new Task(taskDataWithoutId));
          this.render();

          this.exportChartDialog.updateURLInput(this);
        }
      },
    );

    this.addDeveloperDialog = new AddDeveloperDialog(
      this.container,
      (developerName) => {
        this.developers.push(
          new Developer({ name: developerName, tasks: [], ptos: [] }),
        );
        this.render();
        this.addTaskDialog.updateDevelopers(this.developers);
        this.addPTODialog.updateDevelopers(this.developers);
        this.exportChartDialog.updateURLInput(this);
      },
    );

    this.addPTODialog = new AddPTODialog(
      this.container,
      this.developers,
      this.start,
      this.end,
      (ptoData) => {
        const developer = this.developers.find(
          (developer) => developer.getId() === ptoData.developerId,
        );

        if (developer) {
          developer.addPTO(ptoData);

          this.render();

          this.exportChartDialog.updateURLInput(this);
        }
      },
    );

    this.editChartDialog = new EditChartDialog(
      this.container,
      this.name,
      this.start,
      this.end,
      (data) => {
        this.name = data.name;
        this.start = data.start;
        this.end = data.end;
        this.render();

        this.exportChartDialog.updateURLInput(this);
      },
    );

    this.editTaskDialog = new EditTaskDialog(this.container, (taskData) => {
      this.developers.forEach((developer) => {
        const task = developer
          .getTasks()
          .find((task) => task.getId() === taskData.id);

        if (task) {
          if (
            taskData.delete &&
            window.confirm('Do you really want to delete this task?')
          ) {
            developer.removeTask(taskData.id);
            this.render();
            return;
          }

          task.setName(taskData.name);
          task.setLink(taskData.link);
          task.setStart(taskData.start);
          task.setEnd(taskData.end);
          task.setStatus(taskData.status);

          this.render();
        }
      });

      this.exportChartDialog.updateURLInput(this);
    });

    this.editPTODialog = new EditPTODialog(this.container, (ptoData) => {
      const developer = this.developers.find(
        (developer) => developer.getId() === ptoData.developerId,
      );

      const pto = developer?.getPTOs()[ptoData.ptoIndex];

      if (pto) {
        if (
          ptoData.delete &&
          window.confirm('Do you really want to delete this PTO?')
        ) {
          developer.removePTO(ptoData.ptoIndex);
          this.render();
          return;
        }

        pto.start = ptoData.start;
        pto.end = ptoData.end;
        pto.type = ptoData.type as PTOType;

        this.render();
      }

      this.exportChartDialog.updateURLInput(this);
    });

    this.editDeveloperDialog = new EditDeveloperDialog(
      this.container,
      (developerData) => {
        const developer = this.developers.find(
          (developer) => developer.getId() === developerData.id,
        );

        if (developer) {
          if (
            developerData.delete &&
            window.confirm('Do you really want to delete this developer?')
          ) {
            if (
              developer.getTasks().length > 0 ||
              developer.getPTOs().length > 0
            ) {
              alert('Cannot delete a developer with tasks or PTOs');
              return;
            }

            this.developers = this.developers.filter(
              (developer) => developer.getId() !== developerData.id,
            );
            this.render();
            this.addTaskDialog.updateDevelopers(this.developers);
            this.addPTODialog.updateDevelopers(this.developers);
            this.exportChartDialog.updateURLInput(this);
            return;
          }

          developer.setName(developerData.name);

          this.render();
          this.addTaskDialog.updateDevelopers(this.developers);
          this.addPTODialog.updateDevelopers(this.developers);
          this.exportChartDialog.updateURLInput(this);
        }
      },
    );

    this.exportChartDialog = new ExportChartDialog(this.container, this);
    this.importChartDialog = new ImportChartDialog(this.container, (data) => {
      const chart = convertStringToChart(data);
      this.name = chart.getName();
      this.start = chart.getStart();
      this.end = chart.getEnd();
      this.developers = chart.getDevelopers();
      this.timestamp = chart.getTimestamp();

      this.render();
      this.exportChartDialog.updateURLInput(this);
    });
  }

  public render(): void {
    this.clear();

    this.renderChartHeader();
    this.renderButtons();

    this.addDeveloperDialog.render();
    this.addTaskDialog.render();
    this.addPTODialog.render();
    this.editChartDialog.render();
    this.exportChartDialog.render();
    this.importChartDialog.render();
    this.editTaskDialog.render();
    this.editPTODialog.render();
    this.editDeveloperDialog.render();

    const daysArray = this.getDaysArray();
    const weeksArray = this.getWeeksArray();

    const columnCount = daysArray.length + 1;

    const chartContainer = document.createElement('div');
    chartContainer.classList.add('gantt');

    const header = document.createElement('div');
    header.classList.add('gantt__row');
    header.classList.add('gantt__row--months');
    header.style.gridTemplateColumns = `150px repeat(${daysArray.length}, 1fr)`;
    chartContainer.appendChild(header);

    const lines = document.createElement('div');
    lines.classList.add('gantt__row');
    lines.classList.add('gantt__row--lines');
    lines.style.gridTemplateColumns = `150px repeat(${daysArray.length}, 1fr)`;
    chartContainer.appendChild(lines);

    for (let i = 0; i < columnCount; i++) {
      const dayElement = document.createElement('span');

      const day = daysArray[i - 1];
      if (day && day.toDateString() === new Date().toDateString()) {
        dayElement.classList.add('marker');
      }

      lines.appendChild(dayElement);
    }

    const headerFirstField = document.createElement('div');
    headerFirstField.classList.add('gantt__row-first');

    header.appendChild(headerFirstField);

    weeksArray.forEach((week) => {
      const weekElement = document.createElement('span');
      weekElement.innerHTML = week.name;
      weekElement.style.gridColumn = 'span 5';
      weekElement.style.borderLeft = '1px solid black';
      header.appendChild(weekElement);
    });

    this.developers.forEach((developer) => {
      const developerWrapperElement = document.createElement('div');
      developerWrapperElement.classList.add('gantt__row');
      chartContainer.appendChild(developerWrapperElement);

      const developerName = document.createElement('div');
      developerName.classList.add('gantt__row-first');
      developerName.innerHTML = developer.getName();
      developerName.addEventListener('click', () => {
        this.editDeveloperDialog.openDialogWithData(
          developer.getId(),
          developer.getName(),
        );
      });

      developerWrapperElement.appendChild(developerName);

      const developerTasks = document.createElement('ul');
      developerTasks.classList.add('gantt__row-bars');
      developerTasks.style.gridTemplateColumns = `repeat(${daysArray.length}, 1fr)`;
      developerWrapperElement.appendChild(developerTasks);

      developer.getTasks().forEach((task) => {
        const startDay =
          daysArray.findIndex(
            (day) => day.getTime() === task.getStart().getTime(),
          ) + 1;
        const endDay =
          daysArray.findIndex(
            (day) => day.getTime() === task.getEnd().getTime(),
          ) + 2;

        const taskElement = document.createElement('li');
        taskElement.style.gridColumn = `${startDay} / ${endDay}`;
        taskElement.classList.add(this.getStatusClass(task.getStatus()));
        taskElement.innerHTML = task.getName();
        taskElement.addEventListener('click', () => {
          this.editTaskDialog.openDialogWithData({
            id: task.getId(),
            name: task.getName(),
            link: task.getLink(),
            start: task.getStart(),
            end: task.getEnd(),
            status: task.getStatus(),
            min: this.start,
            max: this.end,
          });
        });
        taskElement.addEventListener('mousedown', function (event) {
          // Check if the middle mouse button (button code 1) was clicked
          if (event.button === 1) {
            window.open(task.getLink(), '_blank');
          }
        });

        developerTasks.appendChild(taskElement);
      });

      developer.getPTOs().forEach((pto) => {
        const startDay =
          daysArray.findIndex((day) => day.getTime() === pto.start.getTime()) +
          1;
        const endDay =
          daysArray.findIndex((day) => day.getTime() === pto.end.getTime()) + 2;

        const ptoElement = document.createElement('li');
        ptoElement.classList.add('stripes');

        if (pto.type === PTOType.Sick) {
          ptoElement.classList.add('orange');
          ptoElement.innerHTML = PTO_TYPE_SICK_LABEL;
        } else {
          ptoElement.classList.add('red');
          ptoElement.innerHTML = PTO_TYPE_PTO_LABEL;
        }
        ptoElement.style.gridColumn = `${startDay} / ${endDay}`;
        ptoElement.addEventListener('click', () => {
          this.editPTODialog.openDialogWithData({
            developerId: developer.getId(),
            ptoIndex: developer.getPTOs().indexOf(pto),
            type: pto.type,
            start: pto.start,
            end: pto.end,
            min: this.start,
            max: this.end,
          });
        });

        developerTasks.appendChild(ptoElement);
      });
    });

    this.container.appendChild(chartContainer);
  }

  private clear(): void {
    this.container.innerHTML = '';
  }

  private calculateDuration(start: Date, end: Date): number {
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    const diff = end.getTime() - start.getTime();
    return diff / millisecondsInADay;
  }

  private getWeeksArray(): { name: string }[] {
    const days = this.calculateDuration(this.start, this.end);
    const weeks = Math.ceil(days / 7);

    return Array.from({ length: weeks }, (_, i) => {
      const start = new Date(this.start);
      start.setDate(start.getDate() + i * 7);
      const end = new Date(start);
      end.setDate(end.getDate() + 6);
      return {
        start,
        end,
        name: `Week ${i + 1} (${this.formatDate(start)} - ${this.formatDate(end)})`,
      };
    });
  }

  private getDaysArray(): Date[] {
    const dates = [];
    let currentDate = new Date(this.start);

    while (currentDate <= this.end) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        dates.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  public getDevelopers(): Developer[] {
    return this.developers;
  }

  public getName(): string {
    return this.name;
  }

  public getStart(): Date {
    return this.start;
  }

  public getEnd(): Date {
    return this.end;
  }

  public getTimestamp(): number {
    return this.timestamp;
  }

  public getFormattedTimestamp(): string {
    return this.timestamp > 0 ? new Date(this.timestamp).toLocaleString() : '';
  }

  private formatDate(date: Date): string {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month.toString().padStart(2, '0')}.${day.toString().padStart(2, '0')}`;
  }

  private renderChartHeader(): void {
    const headerContainer = document.createElement('div');
    headerContainer.classList.add('gantt__header');

    const nameElement = document.createElement('h1');
    nameElement.classList.add('gantt__header-name');
    nameElement.innerText = this.name;

    const timestampElement = document.createElement('span');
    timestampElement.classList.add('gantt__header-timestamp');
    timestampElement.innerText = this.getFormattedTimestamp();

    headerContainer.appendChild(nameElement);
    headerContainer.appendChild(timestampElement);

    this.container.appendChild(headerContainer);
  }

  private renderButtons(): void {
    const buttons = document.createElement('div');
    buttons.classList.add('gantt__buttons');

    const leftButtons = document.createElement('div');
    leftButtons.classList.add('gantt__buttons-left');
    buttons.appendChild(leftButtons);

    const rightButtons = document.createElement('div');
    rightButtons.classList.add('gantt__buttons-right');
    buttons.appendChild(rightButtons);

    const addDeveloperButton = this.createButton(
      ADD_DEVELOPER_BUTTON_TEXT,
      () => {
        this.addDeveloperDialog.openDialog();
      },
    );
    leftButtons.appendChild(addDeveloperButton);

    const addTaskButton = this.createButton(ADD_TASK_BUTTON_LABEL, () => {
      this.addTaskDialog.openDialog();
    });
    leftButtons.appendChild(addTaskButton);

    const addPTOButton = this.createButton(ADD_PTO_BUTTON_TEXT, () => {
      this.addPTODialog.openDialog();
    });
    leftButtons.appendChild(addPTOButton);

    const editPIButton = this.createButton(CHART_EDIT_BUTTON_LABEL, () => {
      this.editChartDialog.openDialog();
    });
    rightButtons.appendChild(editPIButton);

    const saveButton = this.createButton(CHART_SAVE_BUTTON_LABEL, () => {
      saveChartToStorage(this);

      const timestampElement: HTMLElement | null = document.querySelector(
        '.gantt__header-timestamp',
      );
      if (timestampElement) {
        this.timestamp = Date.now();
        timestampElement.innerText = this.getFormattedTimestamp();
      }
    });
    rightButtons.appendChild(saveButton);

    const exportButton = this.createButton(CHART_EXPORT_BUTTON_LABEL, () => {
      this.exportChartDialog.openDialog();
    });
    rightButtons.appendChild(exportButton);

    const importButton = this.createButton(CHART_IMPORT_BUTTON_LABEL, () => {
      this.importChartDialog.openDialog();
    });
    rightButtons.appendChild(importButton);

    this.container.appendChild(buttons);
  }

  private createButton(
    text: string,
    callback: {
      (): void;
      (): void;
      (this: HTMLButtonElement, ev: MouseEvent): any;
    },
  ) {
    const button = document.createElement('button');
    button.innerHTML = text;
    button.classList.add('button');
    button.addEventListener('click', callback);
    return button;
  }

  private getStatusClass(status: string): string {
    switch (status) {
      case TaskStatus.Backlog:
        return 'gray';
      case TaskStatus.InProgress:
        return 'blue';
      case TaskStatus.Done:
        return 'green';
      case TaskStatus.Pending:
        return 'orange';
      case TaskStatus.Cancelled:
        return 'red';
      default:
        return '';
    }
  }
}
