import { v4 as uuid } from 'uuid';
import { Task } from './Task';

export enum PTOType {
  PTO = 'pto',
  Sick = 'sick',
}
export interface PTO {
  start: Date;
  end: Date;
  type: PTOType;
}

export interface DeveloperData {
  name: string;
  tasks: Task[];
  ptos: PTO[];
}

export class Developer {
  private id: string;
  private name: string;
  private ptos: PTO[];
  private tasks: Task[];

  constructor(data: DeveloperData) {
    this.id = uuid();
    this.name = data.name;
    this.tasks = data.tasks;
    this.ptos = data.ptos;
  }

  public getId(): string {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public getPTOs(): PTO[] {
    return this.ptos;
  }

  public getTasks(): Task[] {
    return this.tasks;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public addTask(task: Task): void {
    this.tasks.push(task);

    this.tasks.sort((a, b) => a.getStart().getTime() - b.getStart().getTime());
  }

  public removeTask(taskId: string): void {
    this.tasks = this.tasks.filter((task) => task.getId() !== taskId);
  }

  public addPTO(pto: PTO): void {
    this.ptos.push(pto);

    this.ptos.sort((a, b) => a.start.getTime() - b.start.getTime());
  }

  public removePTO(ptoIndex: number): void {
    this.ptos = this.ptos.filter((pto, index) => index !== ptoIndex);
  }
}
