import convertStringToChart from './convertStringToChart';

export default function loadChartFromQueryString() {
  const params = new URLSearchParams(location.search);
  const chartData = params.get('chart');

  if (chartData) {
    const url = new URL(location.href);
    url.searchParams.delete('chart');
    history.replaceState(null, '', url.toString());

    return convertStringToChart(chartData);
  }
}
