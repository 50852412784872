import { Chart } from '@/classes/Chart';
import { AbstractDialog } from './AbstractDialog';
import convertChartToString from '@/utilities/convertChartToString';

export class ExportChartDialog extends AbstractDialog {
  constructor(container: HTMLElement, chart: Chart) {
    super(container);

    this.dialog.innerHTML = `
            <form>
                <textarea type="text" id="share-url" rows="20" readonly></textarea>
                <button type="button" id="copy-button">Copy</button>
            </form>
        `;
    const shareUrlInput = this.dialog.querySelector(
      '#share-url',
    ) as HTMLInputElement;
    shareUrlInput.value = convertChartToString(chart);
    shareUrlInput.addEventListener('click', () => {
      shareUrlInput.select();
    });

    const copyButton = this.dialog.querySelector(
      '#copy-button',
    ) as HTMLButtonElement;
    copyButton.addEventListener('click', () => {
      shareUrlInput.select();
      navigator.clipboard.writeText(shareUrlInput.value);
    });
  }

  updateURLInput(chart: Chart) {
    const shareUrlInput = this.dialog.querySelector(
      '#share-url',
    ) as HTMLInputElement;
    shareUrlInput.value = convertChartToString(chart);
  }
}
