import './styles.scss';

import loadChartFromStorage from '@/utilities/loadChartFromStorage';
import loadChartFromQueryString from '@/utilities/loadChartFromQueryString';
import createEmptyChart from '@/utilities/createEmptyChart';

document.addEventListener('DOMContentLoaded', () => {
  const chart = loadChartFromQueryString() || loadChartFromStorage();
  if (chart) {
    chart.render();
  } else {
    createEmptyChart();
  }
});
